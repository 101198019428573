import { useAppDispatch, useAppSelector } from 'app/hooks';
import { ROUTE_PERMISSION_MAPPING } from 'components/layout';
import { authActions, selectPermission, selectPermissionByFeature } from 'features/Auth/authSlice';
import _ from 'lodash';
import { Redirect, useLocation } from 'react-router-dom';
import authStorage from '../../features/Auth/authStorage';

// parse and recreate config for use
const routes: Array<{ path: RegExp; module: string }> = Object.keys(ROUTE_PERMISSION_MAPPING)
  // sort longest path first
  .sort(function (a, b) {
    return b.length - a.length;
  })
  // convert into more usable format
  .map(function (path) {
    return {
      // create regex
      path: new RegExp('^' + path.replace(/:[^\s/]+/g, '([\\w-]+)') + '$'),
      module: ROUTE_PERMISSION_MAPPING[path],
    };
  });

function getPermissionRouteFromUrl(url: string) {
  let permission: string | undefined = undefined;
  for (var i = 0, l = routes.length; i < l; i++) {
    var found = url.match(routes[i].path);
    if (found) {
      permission = routes[i].module;
      break;
    }
  }

  return permission;
}

export interface PrivateRouteProps {
  children: JSX.Element;
}

export function PrivateRoute({ children }: PrivateRouteProps) {
  const dispatch = useAppDispatch();
  const permission = useAppSelector(selectPermission);
  const permissionByFeature = useAppSelector(selectPermissionByFeature('main_menu'));
  const location = useLocation();

  const permissionFromUrl = getPermissionRouteFromUrl(location.pathname);

  const isLoggedIn = authStorage.isAuthenticated();
  if (!isLoggedIn)
    return (
      <Redirect
        to={{
          pathname: `/login`,
          search: location.pathname !== '/' ? `?redirectUrl=${location.pathname}` : undefined,
        }}
      />
    );

  if (_.size(permission) > 0) {
    // check permission before accessing page
    if (permissionByFeature.length > 0 && permissionFromUrl) {
      if (!permissionByFeature.includes(permissionFromUrl)) {
        return <Redirect to="/" />;
      }
    }
  } else {
    dispatch(authActions.actionLogout());
    return null;
  }

  return children;
}
