import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { errorNotificationReducer } from 'components/common/ErrorNotification/errorNotificationSlice';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { assetListReducer } from 'features/AssetScreen/assetSlice';
import { authReducer } from 'features/Auth/authSlice';
import { companyReducer } from 'features/common/companySlice';
import { inventoryReducer } from 'features/Inventory/inventorySlice';
import { persistStore } from 'redux-persist';

import { assetModelReducer } from 'features/AssetModel/assetModelSlice';
import { manufacturerReducer } from 'features/AssetModel/manufacturerSlice';
import { assetAddReducer, assetEditReducer } from 'features/AssetScreen/assetSlice';
import persistReducer from 'redux-persist/es/persistReducer';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import { history } from 'utils';
import rootSaga from './rootSaga';
import { appReducer } from 'features/common/appSlice';
import { assetStatusReducer } from 'features/AdminMaster/assetStatusSlice';
import { storagePlaceReducer } from 'features/AdminMaster/storagePlaceSlice';
import { assetClassificationReducer } from 'features/AdminMaster/assetClassificationSlice';
import { assetUseReducer } from 'features/AdminMaster/assetUseSlice';
import { approvalStatusReducer } from 'features/common/approvalStatusSlice';
import { inventoryHistoryReducer } from 'features/InventoryHistoryConfirmation/inventoryHistoryConfirmationSlice';
import { inspectionReducer } from 'features/Inspection/inspectionSlice';

const rootReducer = combineReducers({
  router: connectRouter(history),
  app: appReducer,
  asset: assetListReducer,
  assetAdd: assetAddReducer,
  assetEdit: assetEditReducer,
  inventory: inventoryReducer,
  inspection: inspectionReducer,
  company: companyReducer,
  auth: authReducer,
  errorNotification: errorNotificationReducer,
  manufacturer: manufacturerReducer,
  assetModel: assetModelReducer,
  assetStatus: assetStatusReducer,
  storagePlace: storagePlaceReducer,
  assetClassification: assetClassificationReducer,
  assetUse: assetUseReducer,
  approvalStatus: approvalStatusReducer,
  inventoryHistory: inventoryHistoryReducer,
});

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['auth', 'company'],
  stateReconciler: autoMergeLevel2,
};

const persistedReducer = persistReducer<ReturnType<typeof rootReducer>>(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: true,
      serializableCheck: false,
      immutableCheck: true,
    }).concat(sagaMiddleware, routerMiddleware(history)),
});

sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export const persistor = persistStore(store);
