import { FormEvent, useState, useEffect, useRef } from 'react';
import { Card, Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { GlobalModalContext } from '../GlobalModal';
import { EmployeeSearchModalProps, EmployeeSearchModalReturnData } from 'components/common/EmployeeSearchModal';
import { DepartmentSearchModalProps, DepartmentSearchModalReturnData } from 'components/common/DepartmentSearchModal';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Company } from 'features/AssetScreen/models/Company';
import { DepartmentShort } from 'features/AssetScreen/models/Department';
import { StoragePlace } from 'features/AssetScreen/models/StoragePlace';
import { AssetForm } from 'features/AssetScreen/models/Asset';
import { EmployeeShort } from 'features/AssetScreen/models/Employee';
import ja from 'date-fns/locale/ja';
import moment from 'moment';
import { MODAL_TYPES } from 'utils';
import 'react-datepicker/dist/react-datepicker.css';
import './style.scss';
import assetAddService from 'features/AssetScreen/pages/add/assetService';

export interface OwnershipChangeModalProps {
  show: boolean;
  setShowOwnershipModal: (data: boolean) => void;
  setInitialValues: (asset: AssetForm) => void;
  initialValues: AssetForm;
  companies: Company[];
  storage_places: StoragePlace[];
  from_company?: string;
  from_department?: string;
  from_storage_place?: string;
  from_employee?: string;
}
registerLocale('ja', ja);

const constTypeOwnershipChange = {
  CHOOSE_1: 1,
  CHOOSE_2: 2,
  CHOOSE_3: 3,
};

export default function OwnershipChangeModal({
  show,
  companies,
  setShowOwnershipModal,
  setInitialValues,
  initialValues,
  storage_places,
  from_company,
  from_department,
  from_storage_place,
  from_employee,
}: OwnershipChangeModalProps) {
  const { t } = useTranslation();
  const { showGlobalModal } = GlobalModalContext.useGlobalModalContext();
  const [localCompanies, setLocalCompanies] = useState<Company[]>();

  const [employeeError, setEmployeeError] = useState({
    status: false,
    messages: '',
  });
  const [departmentError, setDepartmentError] = useState({
    status: false,
    messages: '',
  });
  const [departmentErrorOption2, setDepartmentErrorOption2] = useState({
    status: false,
    messages: '',
  });
  const [movingDay, setMovingDay] = useState(new Date());
  const handleClose = () => {
    setShowOwnershipModal(false);
  };
  const [localData, setData] = useState<AssetForm>(initialValues);
  const [typeOwnershipChange, setTypeOwnershipChange] = useState(1);

  const [disableOption1, setDisabledOption1] = useState(false);
  const [disableOption2, setDisabledOption2] = useState(false);
  const [disableOption3, setDisabledOption3] = useState(false);

  const [department1, setDepartment1] = useState({ name: '', code: '' });
  const [department2, setDepartment2] = useState({ name: '', code: '' });
  const [employee, setEmployee] = useState({ name: '', code: '' });
  const companyRef1 = useRef<HTMLSelectElement>(null);
  const companyRef2 = useRef<HTMLSelectElement>(null);
  const [defaultValueCompany1, setDefaultValueCompany1] = useState('');
  const [defaultValueCompany2, setDefaultValueCompany2] = useState(''); 
  const refRadioBtn1 = useRef<HTMLInputElement>(null);
  const refRadioBtn2 = useRef<HTMLInputElement>(null);
  const refRadioBtn3 = useRef<HTMLInputElement>(null);

  function resetData() {
    setDisabledOption1(false);
    setDisabledOption2(false);
    setDisabledOption3(false);
    setDefaultValueCompany1('');
    setDefaultValueCompany2('');
    setDepartment1({ name: '', code: '' });
    setDepartment2({ name: '', code: '' });
    setEmployee({name: '', code: ''});
    setTypeOwnershipChange(constTypeOwnershipChange.CHOOSE_1);
  }

  async function callAPICompany() {
    const query = `data_date=${moment(movingDay).endOf('month').format('YYYY-MM-DD')}`;
    await assetAddService
      .fetchCompanies(query)
      .then((item) => {
        setLocalCompanies(item.data);
      })
      .catch((err) => console.log(err));
  }

  useEffect(()=> {
    callAPICompany();
  },[])

  function handleReflect() {
    const type = Number(typeOwnershipChange);
    switch (type) {
      case constTypeOwnershipChange.CHOOSE_1: {
        setInitialValues({
          ...localData,
          current_possession_company: {
            id: '',
            short_name: '',
            cd: '',
          },
          current_possession_department: {
            id: '',
            name: '',
            cd: '',
          },
          current_possession_employee: {
            id: '',
            cd: '',
            name: '',
          },
        });
        break;
      }
      case constTypeOwnershipChange.CHOOSE_2: {
        setInitialValues({
          ...localData,
          current_possession_employee: {
            id: '',
            cd: '',
            name: '',
          },
          current_storage_place: {
            id: '',
            name: '',
          },
        });
        break;
      }
      case constTypeOwnershipChange.CHOOSE_3: {
        setInitialValues({
          ...localData,
          current_storage_place: {
            id: '',
            name: '',
          },
        });
        break;
      }
    }
    console.log(initialValues);
    setShowOwnershipModal(false);
    resetData();
  }

  useEffect(() => {
    const type = Number(typeOwnershipChange);
    switch (type) {
      case constTypeOwnershipChange.CHOOSE_1: {
        setDisabledOption1(false);
        setDisabledOption2(true);
        setDisabledOption3(true);
        break;
      }
      case constTypeOwnershipChange.CHOOSE_2: {
        setDisabledOption1(true);
        setDisabledOption2(false);
        setDisabledOption3(true);
        break;
      }
      case constTypeOwnershipChange.CHOOSE_3: {
        setDisabledOption1(true);
        setDisabledOption2(true);
        setDisabledOption3(false);
        break;
      }
      default: {
        setDisabledOption1(false);
        setDisabledOption2(true);
        setDisabledOption3(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeOwnershipChange]);

  function handleSearchSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
  }

  function handleShowDepartmentSearchModal1() {
    refRadioBtn2.current && refRadioBtn2.current.click();
    showGlobalModal<DepartmentSearchModalProps>(MODAL_TYPES.DEPARTMENT_SEARCH_MODAL, {
      dataDate: moment(movingDay).endOf('month').format('YYYY-MM-DD'),
      companycd: localData.current_possession_company.cd,
      onReflect: (data: DepartmentSearchModalReturnData) => {
        const department: DepartmentShort = {
          id: data.id,
          cd: data.cd,
          name: data.name,
        };

        const company = {
          id: data.company.id,
          cd: data.company.cd,
          short_name: data.company.name,
        };
        
        setDefaultValueCompany1(data.company.id+ '_' + data.company.cd + '_' + data.company.name);
        setDepartment1({ name: department.name, code: department.cd });
        setData({ ...localData, current_possession_department: department, current_possession_company: company });
      },
    });
  }
  function handleShowDepartmentSearchModal2() {
    refRadioBtn3.current && refRadioBtn3.current.click();
    showGlobalModal<DepartmentSearchModalProps>(MODAL_TYPES.DEPARTMENT_SEARCH_MODAL, {
      dataDate: moment(movingDay).endOf('month').format('YYYY-MM-DD'),
      companycd: localData.current_possession_company.cd,
      onReflect: (data: DepartmentSearchModalReturnData) => {
        const department: DepartmentShort = {
          id: data.id,
          cd: data.cd,
          name: data.name,
        };

        const company = {
          id: data.company.id,
          cd: data.company.cd,
          short_name: data.company.name,
        };

        setDefaultValueCompany2(data.company.id+ '_' + data.company.cd + '_' + data.company.name)
        setDepartment2({ name: department.name, code: department.cd });
        setData({ ...localData, current_possession_department: department, current_possession_company: company });
      },
    });
  }
  function handleShowEmployeeSearchModal() {
    refRadioBtn3.current && refRadioBtn3.current.click();
    showGlobalModal<EmployeeSearchModalProps>(MODAL_TYPES.EMPLOYEE_SEARCH_MODAL, {
      dataDate: moment(movingDay).endOf('month').format('YYYY-MM-DD'),
      companycd: localData.current_possession_company.cd,
      departmentcd: department2.code,
      onReflect: (data: EmployeeSearchModalReturnData) => {
        const employee: EmployeeShort = {
          id: data.employeeid,
          cd: data.employee_cd,
          name: data.employee_name,
        };
        const company = {
          id: data.companyid,
          cd: data.company_cd,
          short_name: data.company_name,
        };

        const department: DepartmentShort = {
          id: data.departmentid,
          cd: data.department_cd,
          name: data.department_name,
        };
        setDepartment2({ name: department.name, code: department.cd });
        setDefaultValueCompany2(company.id+ '_' + company.cd + '_' + company.short_name)
        setEmployee({ name: employee.name, code: employee.cd });
        setData({ ...localData, current_possession_employee: employee , current_possession_company: company, current_possession_department: department});
      },
    });
  }

  function handleChangeTypeOwnership(event: any) {
    setTypeOwnershipChange(event.target.value);
  }

  async function handleChangeCompany1(event: any) {
    const companySplit = event.target.value.split('_');
    const company = {
      id: companySplit[0],
      cd: companySplit[1],
      short_name: companySplit[2],
    };
    setDepartment1({ name: '', code: '' });
    setDefaultValueCompany1(company.id+ '_' + company.cd + '_' + company.short_name)
    setData({ ...localData, current_possession_company: company, current_possession_department: {id: '' ,cd: '', name: ''} });
  }

  async function handleChangeCompany2(event: any) {
    const companySplit = event.target.value.split('_');
    const company = {
      id: companySplit[0],
      cd: companySplit[1],
      short_name: companySplit[2],
    };

    setDepartment2({ name: '', code: '' });
    setEmployee({name: '', code: ''});
    setDefaultValueCompany2(company.id+ '_' + company.cd + '_' + company.short_name);
    setData({ ...localData, current_possession_employee: {id: '', cd: '', name: '' } , current_possession_company: company, current_possession_department: {id: '' ,cd: '', name: ''} });
  }

  async function handleStoragePlace(event: any) {
    const query = `storage_place_id=${event.target.value}`;
    const storagePlace = await assetAddService.fetchStoragePlace(query);
    setTypeOwnershipChange(constTypeOwnershipChange.CHOOSE_1);
    setData({
      ...localData,
      current_storage_place: storagePlace.data,
      current_possession_company: storagePlace.data.company,
    });
  }

  async function handleDepartment(event: any) {
    const query = `department_code=${event.target.value}&data_date=${moment(movingDay)
      .endOf('month')
      .format('YYYY-MM-DD')}`;
    if (event.target.value !== '') {
      try {
        const department = await assetAddService.fetchDepartment(query);
        setData({
          ...localData,
          current_possession_department: department.data,
        });
        setDepartment1({ name: department.data.name, code: department.data.cd });
      } catch (err: any) {
        const department = err.response.data;
        setDepartmentError({
          ...departmentError,
          status: true,
          messages: department.message,
        });
      }
    } else {
      setDepartmentError({
        ...departmentError,
        status: false,
        messages: '',
      });
    }
  }

  async function handleDepartmentOption2(event: any) {
    const query = `department_code=${event.target.value}&data_date=${moment(movingDay)
      .endOf('month')
      .format('YYYY-MM-DD')}`;
    if (event.target.value !== '') {
      try {
        const department = await assetAddService.fetchDepartment(query);
        setData({
          ...localData,
          current_possession_department: department.data,
        });
        setDepartment2({ name: department.data.name, code: department.data.cd });
      } catch (err: any) {
        const department = err.response.data;
        setDepartmentErrorOption2({
          ...departmentErrorOption2,
          status: true,
          messages: department.message,
        });
      }
    } else {
      setDepartmentErrorOption2({
        ...departmentErrorOption2,
        status: false,
        messages: '',
      });
    }
  }

  async function handleEmployee(event: any) {
    const query = `employee_code=${event.target.value}`;
    if (event.target.value !== '') {
      try {
        const employee = await assetAddService.fetchEmployee(query);
        setData({ ...localData, current_possession_employee: employee.data });
      } catch (err: any) {
        const employee = err.response.data;
        setEmployeeError({
          ...employeeError,
          status: true,
          messages: employee.message,
        });
      }
    } else {
      setEmployeeError({
        ...employeeError,
        status: false,
        messages: '',
      });
    }
  }

  return (
    <Modal show={show} backdrop="static" centered size="lg" dialogClassName="modal-90w">
      <Modal.Header className="bg-primary justify-content-center">
        <Modal.Title className="fs-6 text-white">{t('OwnershipChangeModal.title')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form className="mb-8" onSubmit={handleSearchSubmit}>
          <Card className="mt-4">
            <Card.Body>
              <Row>
                <Form.Label className="col-sm-2 col-form-label">{t('OwnershipChangeModal.from_company_id')}</Form.Label>
                <Col xs sm="3">
                  <Form.Control
                    className="form-control-sm"
                    type="text"
                    name="from_company_id"
                    value={from_company}
                    disabled
                  ></Form.Control>
                </Col>

                <Form.Label className="col-sm-3 col-form-label">
                  {t('OwnershipChangeModal.from_department_id')}
                </Form.Label>
                <Col xs sm="3">
                  <Form.Control
                    className="form-control-sm"
                    type="text"
                    name="from_department_id"
                    value={from_department}
                    disabled
                  ></Form.Control>
                </Col>
              </Row>
              <Row>
                <Form.Label className="col-sm-2 col-form-label">{t('OwnershipChangeModal.from_storage_id')}</Form.Label>
                <Col xs sm="3">
                  <Form.Control
                    className="form-control-sm"
                    type="text"
                    name="from_storage_id"
                    value={from_storage_place}
                    disabled
                  ></Form.Control>
                </Col>

                <Form.Label className="col-sm-3 col-form-label">
                  {t('OwnershipChangeModal.from_employee_id')}
                </Form.Label>
                <Col xs sm="3">
                  <Form.Control
                    className="form-control-sm"
                    type="text"
                    name="from_employee_id"
                    value={from_employee}
                    disabled
                  ></Form.Control>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card className="mt-4">
            <Card.Body>
              <Row>
                <Col xs sm="3">
                  <Form.Check
                    type="radio"
                    label={t('OwnershipChangeModal.radio_button_1')}
                    defaultChecked
                    value={constTypeOwnershipChange.CHOOSE_1}
                    name="type_ownership_change"
                    onChange={handleChangeTypeOwnership}
                    id="radio_button_1"
                    ref={refRadioBtn1}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs sm="1"></Col>
                <Form.Label className="col-sm-3 col-form-label">
                  {t('OwnershipChangeModal.to_storage_place_id')}
                </Form.Label>
                <Col xs sm="3">
                  <Form.Select
                    id="to_storage_place_id"
                    size="sm"
                    name="to_storage_place_id"
                    onChange={handleStoragePlace}
                    disabled={disableOption1}
                  >
                    <option value="">---{t('OwnershipChangeModal.to_storage_place_id')}---</option>
                    {storage_places.map((storage_place) => {
                      return (
                        <option key={storage_place.id} value={storage_place.id}>
                          {storage_place.name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs sm="6">
                  <Form.Check
                    type="radio"
                    label={t('OwnershipChangeModal.radio_button_2')}
                    value={constTypeOwnershipChange.CHOOSE_2}
                    name="type_ownership_change"
                    onChange={handleChangeTypeOwnership}
                    id="radio_button_2"
                    ref={refRadioBtn2}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs sm="1"></Col>
                <Form.Label className="col-sm-3 col-form-label">{t('OwnershipChangeModal.to_company_id')}</Form.Label>
                <Col xs sm="3">
                  <Form.Select
                    id="to_company_id"
                    size="sm"
                    name="to_company_id"
                    onChange={handleChangeCompany1}
                    disabled={disableOption2}
                    ref={companyRef1}
                    value={defaultValueCompany1}
                  >
                    <option value="">---{t('OwnershipChangeModal.to_company_id')}---</option>
                    {localCompanies &&
                      localCompanies.map((company: Company) => {
                        return (
                          <option key={company.id} value={`${company.id}_${company.cd}_${company.name}`}>
                            {company.name}
                          </option>
                        );
                      })}
                  </Form.Select>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col xs sm="1"></Col>
                <Form.Label className="col-sm-2 col-form-label" htmlFor="to_department_id">
                  {t('OwnershipChangeModal.to_department_id')}
                </Form.Label>
                <Col xs sm="3">
                  <Form.Control
                    className="form-control-sm"
                    id="to_department_id"
                    name="to_department_id"
                    isInvalid={departmentError.status}
                    defaultValue={department1.code}
                    onChange={handleDepartment}
                    disabled={disableOption2}
                  />
                  <Form.Control.Feedback type="invalid">{departmentError.messages}</Form.Control.Feedback>
                </Col>
                <Form.Label className="col-sm-2 col-form-label" htmlFor="to_department_name">
                  {t('OwnershipChangeModal.to_department_name')}
                </Form.Label>
                <Col xs sm="2">
                  <Form.Control
                    className="form-control-sm"
                    id="to_department_name"
                    name="to_department_name"
                    value={department1.name}
                    disabled
                  />
                </Col>
                <Col xs sm="2">
                  <Button size="sm" onClick={handleShowDepartmentSearchModal1}>
                    {t('OwnershipChangeModal.to_department_name_btn')}
                  </Button>
                </Col>
              </Row>
              {/* Row 3 */}
              <Row>
                <Col xs sm="6">
                  <Form.Check
                    type="radio"
                    label={t('OwnershipChangeModal.radio_button_3')}
                    value={constTypeOwnershipChange.CHOOSE_3}
                    name="type_ownership_change"
                    onChange={handleChangeTypeOwnership}
                    id="radio_button_3"
                    ref={refRadioBtn3}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs sm="1"></Col>
                <Form.Label className="col-sm-3 col-form-label">{t('OwnershipChangeModal.to_company_id')}</Form.Label>
                <Col xs sm="3">
                  <Form.Select
                    id="to_company_id"
                    size="sm"
                    name="to_company_id"
                    onChange={handleChangeCompany2}
                    disabled={disableOption3}
                    ref={companyRef2}
                    value={defaultValueCompany2}
                  >
                    <option value="">---{t('OwnershipChangeModal.to_company_id')}---</option>
                    {localCompanies &&
                      localCompanies.map((company: Company) => {
                        return (
                          <option key={company.id} value={`${company.id}_${company.cd}_${company.name}`}>
                            {company.name}
                          </option>
                        );
                      })}
                  </Form.Select>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col xs sm="1"></Col>
                <Form.Label className="col-sm-2 col-form-label" htmlFor="to_department_id">
                  {t('OwnershipChangeModal.to_department_id')}
                </Form.Label>
                <Col xs sm="3">
                  <Form.Control
                    className="form-control-sm"
                    id="to_department_id"
                    name="to_department_id"
                    isInvalid={departmentErrorOption2.status}
                    defaultValue={department2.code}
                    onChange={handleDepartmentOption2}
                    disabled={disableOption3}
                  />
                  {departmentErrorOption2.status && (
                    <Form.Control.Feedback type="invalid">{departmentErrorOption2.messages}</Form.Control.Feedback>
                  )}
                </Col>
                <Form.Label className="col-sm-2 col-form-label" htmlFor="to_department_name">
                  {t('OwnershipChangeModal.to_department_name')}
                </Form.Label>
                <Col xs sm="2">
                  <Form.Control
                    className="form-control-sm"
                    id="to_department_name"
                    name="to_department_name"
                    value={department2.name}
                    disabled
                  />
                </Col>
                <Col xs sm="2">
                  <Button size="sm" onClick={handleShowDepartmentSearchModal2}>
                    {t('OwnershipChangeModal.to_department_name_btn')}
                  </Button>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col xs sm="1"></Col>
                <Form.Label className="col-sm-2 col-form-label" htmlFor="to_employee_id">
                  {t('OwnershipChangeModal.to_employee_id')}
                </Form.Label>
                <Col xs sm="3">
                  <Form.Control
                    className="form-control-sm"
                    id="to_employee_id"
                    name="to_employee_id"
                    isInvalid={employeeError.status}
                    onChange={handleEmployee}
                    defaultValue={employee.code}
                    disabled={disableOption3}
                  />
                  <Form.Control.Feedback type="invalid">{employeeError.messages}</Form.Control.Feedback>
                </Col>
                <Form.Label className="col-sm-2 col-form-label" htmlFor="to_employee_name">
                  {t('OwnershipChangeModal.to_employee_name')}
                </Form.Label>
                <Col xs sm="2">
                  <Form.Control
                    className="form-control-sm"
                    id="to_employee_name"
                    name="to_employee_name"
                    value={employee.name}
                    disabled
                  />
                </Col>
                <Col xs sm="2">
                  <Button size="sm" onClick={handleShowEmployeeSearchModal}>
                    {t('OwnershipChangeModal.to_employee_name_btn')}
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Row className="mt-2">
            <Col xs sm="4"></Col>
            <Form.Label className="col-sm-2 col-form-label p-0" htmlFor="moving_day">
              <span className="text-danger">※</span>
              {t('OwnershipChangeModal.moving_day')}
            </Form.Label>
            <Col xs sm="2">
              <DatePicker
                className="form-control"
                name="moving_day"
                dateFormat="yyyy/MM/dd"
                onChange={async (date: Date) => {
                  if (!date) {
                    date = new Date();
                  }
                  setMovingDay(date);
                  setData({
                    ...localData,
                    moving_day: moment(date).format('YYYY/MM/DD'),
                  });
                  const query = `data_date=${moment(date).endOf('month').format('YYYY-MM-DD')}`;
                  await assetAddService
                    .fetchCompanies(query)
                    .then((item) => {
                      setLocalCompanies(item.data);
                    })
                    .catch((err) => console.log(err));
                }}
                selected={movingDay}
              />
            </Col>
            <Col xs sm="4"></Col>
          </Row>
        </Form>
      </Modal.Body>

      <Modal.Footer className="justify-content-center">
        <Button variant="primary" className="me-3" onClick={handleReflect}>
          {t('Department_Search_Modal.reflect')}
        </Button>
        <Button variant="light" onClick={handleClose}>
          {t('Department_Search_Modal.close')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
