import ExportLink from 'components/common/ExportLink';
import { FaDownload } from 'react-icons/fa';
import { AssetListProps } from 'features/AssetScreen/models/Asset';
import { useEffect, useState } from 'react';

export default function ExportCSV({ assets }: AssetListProps) {
  const [data, setData] = useState<any>([]);
  useEffect(() => {
    let dataCSV = [];
    for (const asset of assets.data) {
      dataCSV.push({
        management_no: asset.management_no,
        purchased_date: asset.purchased_date,
        size: asset.size,
        manufacturer_serial_number: asset.manufacturer_serial_number,
        external_order_number: asset.external_order_number,
        compressed_output: asset.compressed_output,
        accounting_class_id: asset.accounting_class.id,
        accounting_class_name: asset.accounting_class.name,
        accounting_no: asset.accounting_no,
        leasing_divided_contract_company: asset.leasing_divided_contract_company,
        type_id: asset.type.id,
        type_name: asset.type.name,
        model_id: asset.model.id,
        model_name: asset.model.name,
        manufacturer_id: asset.manufacturer.id,
        manufacturer_name: asset.manufacturer.name,
        asset_classification_id: asset.classification.id,
        asset_classification_cd: asset.classification.cd,
        asset_classification_name: asset.classification.name,
        model_number: asset.model_number,
        mac_address: asset.mac_address,
        gas_specie: asset.gas_specie,
        power_supply: asset.power_supply,
        purchased_price: asset.purchased_price,
        purchased_method: asset.purchased_method,
        contract_expiry_date: asset.contract_expiry_date,
        current_use_id: asset.current_use?.id,
        current_use_name: asset.current_use?.name,
        current_status_id: asset.current_status?.id,
        current_status_name: asset.current_status?.name,
        current_installation_location: asset.current_installation_location,
        current_use_location: asset.current_use_location,
        current_possession_company_id: asset.current_possession_company?.id,
        current_possession_company_cd: asset.current_possession_company?.cd,
        current_possession_company_name: asset.current_possession_company?.name,
        current_possession_company_short_name: asset.current_possession_company?.short_name,
        current_possession_department_id: asset.current_possession_department?.id,
        current_possession_department_cd: asset.current_possession_department?.cd,
        current_possession_restaurant_cd: asset.current_possession_department?.restaurant_cd,
        current_possession_department_name: asset.current_possession_department?.name,
        current_possession_employee_id: asset.current_possession_employee?.id,
        current_possession_employee_cd: asset.current_possession_employee?.cd,
        current_possession_employee_name: asset.current_possession_employee?.username,
        current_storage_place_id: asset.current_storage_place?.id,
        current_storage_place_name: asset.current_storage_place?.name,
      });
    }
    setData(dataCSV);
  }, [assets]);

  const headers = [
    { header: 'management_no', key: 'management_no' },
    { header: 'purchased_date', key: 'purchased_date' },
    { header: 'size', key: 'size' },
    { header: 'manufacturer_serial_number', key: 'manufacturer_serial_number' },
    { header: 'external_order_number', key: 'external_order_number' },
    { header: 'compressed_output', key: 'compressed_output' },
    { header: 'accounting_class_id', key: 'accounting_class_id' },
    { header: 'accounting_class_name', key: 'accounting_class_name' },
    { header: 'accounting_no', key: 'accounting_no' },
    {
      header: 'leasing_divided_contract_company',
      key: 'leasing_divided_contract_company',
    },
    { header: 'type_id', key: 'type_id' },
    { header: 'type_name', key: 'type_name' },
    { header: 'model_id', key: 'model_id' },
    { header: 'model_name', key: 'model_name' },
    { header: 'manufacturer_id', key: 'manufacturer_id' },
    { header: 'manufacturer_name', key: 'manufacturer_name' },
    { header: 'asset_classification_id', key: 'asset_classification_id' },
    { header: 'asset_classification_cd', key: 'asset_classification_cd' },
    { header: 'asset_classification_name', key: 'asset_classification_name' },
    { header: 'model_number', key: 'model_number' },
    { header: 'mac_address', key: 'mac_address' },
    { header: 'gas_specie', key: 'gas_specie' },
    { header: 'power_supply', key: 'power_supply' },
    { header: 'purchased_price', key: 'purchased_price' },
    { header: 'purchased_method', key: 'purchased_method' },
    { header: 'contract_expiry_date', key: 'contract_expiry_date' },
    { header: 'current_use_id', key: 'current_use_id' },
    { header: 'current_use_name', key: 'current_use_name' },
    { header: 'current_status_id', key: 'current_status_id' },
    { header: 'current_status_name', key: 'current_status_name' },
    {
      header: 'current_installation_location',
      key: 'current_installation_location',
    },
    { header: 'current_use_location', key: 'current_use_location' },
    {
      header: 'current_possession_company_id',
      key: 'current_possession_company_id',
    },
    {
      header: 'current_possession_company_cd',
      key: 'current_possession_company_cd',
    },
    {
      header: 'current_possession_company_name',
      key: 'current_possession_company_name',
    },
    {
      header: 'current_possession_company_short_name',
      key: 'current_possession_company_short_name',
    },
    {
      header: 'current_possession_department_id',
      key: 'current_possession_department_id',
    },
    {
      header: 'current_possession_department_cd',
      key: 'current_possession_department_cd',
    },
    {
      header: 'current_possession_restaurant_cd',
      key: 'current_possession_restaurant_cd',
    },
    {
      header: 'current_possession_department_name',
      key: 'current_possession_department_name',
    },
    {
      header: 'current_possession_employee_id',
      key: 'current_possession_employee_id',
    },
    {
      header: 'current_possession_employee_cd',
      key: 'current_possession_employee_cd',
    },
    {
      header: 'current_possession_employee_name',
      key: 'current_possession_employee_name',
    },
    { header: 'current_storage_place_id', key: 'current_storage_place_id' },
    { header: 'current_storage_place_name', key: 'current_storage_place_name' },
  ];

  return (
    <>
      <ExportLink
        target="_blank"
        filename={'assets.csv'}
        data={data}
        headers={headers}
        className="btn btn-primary csv-export"
      >
        <FaDownload /> CSV出力
      </ExportLink>
    </>
  );
}
