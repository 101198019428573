import { useAppDispatch, useAppSelector } from 'app/hooks';
import { GlobalModalContext } from 'components/common/GlobalModal';
import StatusHeader from 'components/common/StatusHeader';
import { useEffect, useState, useRef } from 'react';
import { Row, Col, Card, Table, Form, Button, Accordion } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { MODAL_TYPES } from 'utils';
import './MovementHistory.scss';
import { EmployeeSearchModalProps, EmployeeSearchModalReturnData } from 'components/common/EmployeeSearchModal';
import { DepartmentSearchModalProps, DepartmentSearchModalReturnData } from 'components/common/DepartmentSearchModal';
import { DepartmentShort } from 'features/AssetScreen/models/Department';
import { EmployeeShort } from 'features/AssetScreen/models/Employee';
import { TransferHistory } from 'features/AssetScreen/models/Asset';
import moment from 'moment';
import assetAddService from 'features/AssetScreen/pages/add/assetService';
import movementHisotryService from 'features/MovementHistory/service';
import { assetActions, selectPreDataLoad } from 'features/AssetScreen/pages/index/assetListSlice';
import { selectJobInfo } from 'features/Auth/authSlice';
import { selectPermissionByFeature } from 'features/Auth/authSlice';
import { useHistory } from 'react-router-dom';
import ExportCSV from '../components/ExportCSV';
import { Company } from 'features/AssetScreen/models/Company';
import { MovementHistoryCSV } from '../models/MovementHistoryCSV';
import CustomDatePicker from 'components/common/DatePickerCustom/CustomDatePicker';
import CustomInputDatePicker from '../components/CustomInputDatePicker';

const constType = {
  CHOOSE_1: 1,
  CHOOSE_2: 2,
  CHOOSE_3: 3,
};

function MovementHistory() {
  const history = useHistory();
  const fromChoose1Ref = useRef() as React.MutableRefObject<HTMLInputElement>;
  const fromChoose2Ref = useRef() as React.MutableRefObject<HTMLInputElement>;
  const fromChoose3Ref = useRef() as React.MutableRefObject<HTMLInputElement>;

  const [fromDisableOption1, setFromDisabledOption1] = useState(false);
  const [fromDisableOption2, setFromDisabledOption2] = useState(false);
  const [fromDisableOption3, setFromDisabledOption3] = useState(false);

  const [toDisableOption1, setToDisabledOption1] = useState(false);
  const [toDisableOption2, setToDisabledOption2] = useState(false);
  const [toDisableOption3, setToDisabledOption3] = useState(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { showGlobalModal } = GlobalModalContext.useGlobalModalContext();
  const preDataLoad = useAppSelector(selectPreDataLoad).data;
  const jobInfo = useAppSelector(selectJobInfo);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [assetTransferHistory, setAssetTransferHistory] = useState<TransferHistory[]>([]);
  const [companies, setCompanies] = useState<Company[]>(preDataLoad.companies);

  const [search, setSearch] = useState({
    applied_start_date: moment(startDate).format('YYYY-MM-DD'),
    applied_end_date: moment(endDate).format('YYYY-MM-DD'),
    from_type: 1,
    from_company_cd: '',
    from_company_id: '',
    from_department_id: '',
    from_department_cd: '',
    from_department_name: '',
    from_employee_cd: '',
    from_employee_name: '',
    from_storage_place_id: '',
    to_company_cd: '',
    to_company_id: '',
    to_department_id: '',
    to_department_cd: '',
    to_department_name: '',
    to_employee_cd: '',
    to_employee_name: '',
    to_storage_place_id: '',
    to_type: 1,
    management_no: '',
    accounting_no: '',
    choose_department_id: jobInfo?.department_id,
    choose_company_cd: jobInfo?.company_cd,
  });
  const toChoose1Ref = useRef() as React.MutableRefObject<HTMLInputElement>;
  const toChoose2Ref = useRef() as React.MutableRefObject<HTMLInputElement>;
  const toChoose3Ref = useRef() as React.MutableRefObject<HTMLInputElement>;
  const fromCompany1Ref = useRef<HTMLSelectElement>(null);
  const fromCompany2Ref = useRef<HTMLSelectElement>(null);
  const toCompany1Ref = useRef<HTMLSelectElement>(null);
  const toCompany2Ref = useRef<HTMLSelectElement>(null);

  const [defaultValueFromCompany1, setDefaultvalueFromCompany1] = useState('');
  const [defaultValueFromCompany2, setDefaultvalueFromCompany2] = useState('');
  const [defaultValueToCompany1, setDefaultvalueToCompany1] = useState('');
  const [defaultValueToCompany2, setDefaultvalueToCompany2] = useState('');

  const [typeFrom, setTypeFrom] = useState(1);
  const [typeTo, setTypeTo] = useState(1);

  const [fromDepartment1, setFromDepartment1] = useState({ name: '', code: '' });
  const [fromDepartment2, setFromDepartment2] = useState({ name: '', code: '' });

  const [toDepartment1, setToDepartment1] = useState({ name: '', code: '' });
  const [toDepartment2, setToDepartment2] = useState({ name: '', code: '' });

  async function searchCompanies(data_date: string) {
    await movementHisotryService
      .fetchCompanies(data_date)
      .then((companies) => {
        setCompanies(companies.data);
      })
      .catch(() => {
        setSearch({ ...search, from_department_id: '', from_department_cd: '', from_department_name: '' });
      });
  }
  useEffect(() => {
    if (startDate) {
      const query: string = moment(startDate).endOf('month').format('YYYY-MM-DD');
      searchCompanies(query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate]);

  useEffect(() => {
    if (preDataLoad.companies.length) {
      setCompanies(preDataLoad.companies);
    }
  }, [preDataLoad.companies]);

  useEffect(() => {
    const typeF = Number(typeFrom);
    const typeT = Number(typeTo);
    switch (typeF) {
      case constType.CHOOSE_1: {
        setFromDisabledOption1(false);
        setFromDisabledOption2(true);
        setFromDisabledOption3(true);
        break;
      }
      case constType.CHOOSE_2: {
        setFromDisabledOption1(true);
        setFromDisabledOption2(false);
        setFromDisabledOption3(true);
        break;
      }
      case constType.CHOOSE_3: {
        setFromDisabledOption1(true);
        setFromDisabledOption2(true);
        setFromDisabledOption3(false);
        break;
      }
      default: {
        setFromDisabledOption1(false);
        setFromDisabledOption2(true);
        setFromDisabledOption3(true);
      }
    }

    switch (typeT) {
      case constType.CHOOSE_1: {
        setToDisabledOption1(false);
        setToDisabledOption2(true);
        setToDisabledOption3(true);
        break;
      }
      case constType.CHOOSE_2: {
        setToDisabledOption1(true);
        setToDisabledOption2(false);
        setToDisabledOption3(true);
        break;
      }
      case constType.CHOOSE_3: {
        setToDisabledOption1(true);
        setToDisabledOption2(true);
        setToDisabledOption3(false);
        break;
      }
      default: {
        setToDisabledOption1(false);
        setToDisabledOption2(true);
        setToDisabledOption3(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeFrom, typeTo]);

  useEffect(() => {
    dispatch(assetActions.fetchPreLoad());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  function handleChangeTypeFrom(event: any) {
    setTypeFrom(event.target.value);
    setSearch({ ...search, from_type: Number(event.target.value) });
  }

  function handleFromShowDepartmentSearchModal1() {
    showGlobalModal<DepartmentSearchModalProps>(MODAL_TYPES.DEPARTMENT_SEARCH_MODAL, {
      dataDate: moment(startDate).endOf('month').format('YYYY-MM-DD'),
      companycd: search.from_company_cd,
      onReflect: (data: DepartmentSearchModalReturnData) => {
        const department: DepartmentShort = {
          id: data.id,
          cd: data.cd,
          name: data.name,
        };
        const company = {
          id: data.company.id,
          cd: data.company.cd,
          short_name: data.company.name,
        };
        setDefaultvalueFromCompany1(company.id+ '_' + company.cd + '_' + company.short_name);
        setFromDepartment1({ name: department.name, code: department.cd });
        setSearch({
          ...search,
          from_department_id: department.id.toString(),
          from_department_cd: department.cd,
          from_department_name: department.name,
          from_company_cd: company.cd, 
          from_company_id: company.id.toString(),
        });
      },
    });
  }
  function handleFromShowDepartmentSearchModal2() {
    showGlobalModal<DepartmentSearchModalProps>(MODAL_TYPES.DEPARTMENT_SEARCH_MODAL, {
      dataDate: moment(startDate).endOf('month').format('YYYY-MM-DD'),
      companycd: search.from_company_cd,
      onReflect: (data: DepartmentSearchModalReturnData) => {
        const department: DepartmentShort = {
          id: data.id,
          cd: data.cd,
          name: data.name,
        };
        const company = {
          id: data.company.id,
          cd: data.company.cd,
          short_name: data.company.name,
        };
        setDefaultvalueFromCompany2(company.id+ '_' + company.cd + '_' + company.short_name);
        setFromDepartment2({ name: department.name, code: department.cd });
        setSearch({
          ...search,
          from_department_id: department.id.toString(),
          from_department_cd: department.cd,
          from_department_name: department.name,
          from_company_cd: company.cd, 
          from_company_id: company.id.toString(),
        });
      },
    });
  }
  function handleFromShowEmployeeSearchModal() {
    showGlobalModal<EmployeeSearchModalProps>(MODAL_TYPES.EMPLOYEE_SEARCH_MODAL, {
      dataDate: moment(startDate).endOf('month').format('YYYY-MM-DD'),
      companycd: search.from_company_cd,
      departmentcd: search.from_department_cd,
      onReflect: (data: EmployeeSearchModalReturnData) => {
        const employee: EmployeeShort = {
          id: data.employeeid,
          cd: data.employee_cd,
          name: data.employee_name,
        };
        const company = {
          id: data.companyid,
          cd: data.company_cd,
          short_name: data.company_name,
        };
        setDefaultvalueFromCompany2(company.id+ '_' + company.cd + '_' + company.short_name);
        setSearch({ ...search, from_employee_cd: employee.cd, from_employee_name: employee.name, 
          from_company_cd: company.cd, 
          from_company_id: company.id.toString(), });
      },
    });
  }

  async function handleFromDepartment1(event: any) {
    const query = `department_code=${event.target.value}&data_date=${moment(startDate)
      .endOf('month')
      .format('YYYY-MM-DD')}`;
    fromChoose2Ref.current.click();
    if (event.target.value !== '') {
      await assetAddService
        .fetchDepartment(query)
        .then((department) => {
          setFromDepartment1({ name: department.data.name, code: department.data.cd });
          setSearch({
            ...search,
            from_department_id: department.data.id.toString(),
            from_department_cd: department.data.cd,
            from_department_name: department.data.name,
          });
        })
        .catch(() => {
          setSearch({ ...search, from_department_id: '', from_department_cd: '', from_department_name: '' });
        });
    }
  }

  async function handleFromDepartment2(event: any) {
    const query = `department_code=${event.target.value}&data_date=${moment(startDate)
      .endOf('month')
      .format('YYYY-MM-DD')}`;
    fromChoose3Ref.current.click();
    if (event.target.value !== '') {
      await assetAddService
        .fetchDepartment(query)
        .then((department) => {
          setFromDepartment2({ name: department.data.name, code: department.data.cd });
          setSearch({
            ...search,
            from_department_id: department.data.id.toString(),
            from_department_cd: department.data.cd,
            from_department_name: department.data.name,
          });
        })
        .catch(() => {
          setSearch({ ...search, from_department_id: '', from_department_cd: '', from_department_name: '' });
        });
    }
  }

  async function handleFromEmployee(event: any) {
    const query = `employee_code=${event.target.value}&data_date=${moment(startDate)
      .endOf('month')
      .format('YYYY-MM-DD')}`;
    fromChoose3Ref.current.click();
    if (event.target.value !== '') {
      await assetAddService
        .fetchEmployee(query)
        .then((employee) => {
          setSearch({ ...search, from_employee_cd: employee.data.cd, from_employee_name: employee.data.name });
        })
        .catch(() => {
          setSearch({ ...search, from_employee_cd: '', from_employee_name: '' });
        });
    }
  }

  async function handleFromStoragePlace(event: any) {
    fromChoose1Ref.current.click();
    const storage_place_id = event.target.value;
    setSearch({ ...search, from_storage_place_id: storage_place_id });
  }

  function handleFromChangeCompany1(event: any) {
    const companySplit = event.target.value.split('_');
    fromChoose2Ref.current.click();
    const company = {
      id: companySplit[0],
      cd: companySplit[1],
      short_name: companySplit[2],
    };
    setFromDepartment1({name: '', code: ''});
    setDefaultvalueFromCompany1(company.id+ '_' + company.cd + '_' + company.short_name);
    setSearch({ ...search, from_company_cd: company.cd, from_company_id: company.id, from_department_id: '', from_department_cd: '', from_department_name: '' });
  }
  function handleFromChangeCompany2(event: any) {
    const companySplit = event.target.value.split('_');
    fromChoose3Ref.current.click();
    const company = {
      id: companySplit[0],
      cd: companySplit[1],
      short_name: companySplit[2],
    };
    setFromDepartment2({name: '', code: ''});
    setDefaultvalueFromCompany2(company.id+ '_' + company.cd + '_' + company.short_name);
    setSearch({ ...search, from_company_cd: company.cd, 
      from_company_id: company.id, 
      from_department_id: '', 
      from_department_cd: '', from_department_name: '',
      from_employee_cd: '', from_employee_name: '' });
  }

  function handleChangeTypeTo(event: any) {
    setTypeTo(event.target.value);
    setSearch({ ...search, to_type: event.target.value });
  }

  function handleToShowDepartmentSearchModal1() {
    showGlobalModal<DepartmentSearchModalProps>(MODAL_TYPES.DEPARTMENT_SEARCH_MODAL, {
      dataDate: moment(startDate).endOf('month').format('YYYY-MM-DD'),
      companycd: search.to_company_cd,
      onReflect: (data: DepartmentSearchModalReturnData) => {
        const department: DepartmentShort = {
          id: data.id,
          cd: data.cd,
          name: data.name,
        };
        const company = {
          id: data.company.id,
          cd: data.company.cd,
          short_name: data.company.name,
        };
        setDefaultvalueToCompany1(company.id+ '_' + company.cd + '_' + company.short_name);
        setToDepartment1({ name: department.name, code: department.cd });
        setSearch({
          ...search,
          to_department_id: department.id.toString(),
          to_department_cd: department.cd,
          to_department_name: department.name,
          to_company_cd: company.cd,
          to_company_id: company.id.toString(),
        });
      },
    });
  }
  function handleToShowDepartmentSearchModal2() {
    showGlobalModal<DepartmentSearchModalProps>(MODAL_TYPES.DEPARTMENT_SEARCH_MODAL, {
      dataDate: moment(startDate).endOf('month').format('YYYY-MM-DD'),
      companycd: search.to_company_cd,
      onReflect: (data: DepartmentSearchModalReturnData) => {
        const department: DepartmentShort = {
          id: data.id,
          cd: data.cd,
          name: data.name,
        };
        const company = {
          id: data.company.id,
          cd: data.company.cd,
          short_name: data.company.name,
        };
        setToDepartment2({ name: department.name, code: department.cd });
        setDefaultvalueToCompany2(company.id+ '_' + company.cd + '_' + company.short_name);
        setSearch({
          ...search,
          to_department_id: department.id.toString(),
          to_department_cd: department.cd,
          to_department_name: department.name,
          to_company_cd: company.cd,
          to_company_id: company.id.toString(),
        });
      },
    });
  }
  function handleToShowEmployeeSearchModal() {
    showGlobalModal<EmployeeSearchModalProps>(MODAL_TYPES.EMPLOYEE_SEARCH_MODAL, {
      dataDate: moment(startDate).endOf('month').format('YYYY-MM-DD'),
      companycd: search.to_company_cd,
      departmentcd: search.to_department_cd,
      onReflect: (data: EmployeeSearchModalReturnData) => {
        const employee: EmployeeShort = {
          id: data.employeeid,
          cd: data.employee_cd,
          name: data.employee_name,
        };
        const company = {
          id: data.companyid,
          cd: data.company_cd,
          short_name: data.company_name,
        };
        setDefaultvalueToCompany2(company.id+ '_' + company.cd + '_' + company.short_name);
        setSearch({ ...search, to_employee_cd: employee.cd, to_employee_name: employee.name, to_company_id: company.id.toString(), to_company_cd: company.cd });
      },
    });
  }

  async function handleToDepartment1(event: any) {
    const query = `department_code=${event.target.value}&data_date=${moment(startDate)
      .endOf('month')
      .format('YYYY-MM-DD')}`;
    toChoose2Ref.current.click();
    if (event.target.value !== '') {
      await assetAddService
        .fetchDepartment(query)
        .then((department) => {
          setToDepartment1({ name: department.data.name, code: department.data.cd });
          setSearch({
            ...search,
            to_department_id: department.data.id.toString(),
            to_department_cd: department.data.cd,
            to_department_name: department.data.name,
          });
        })
        .catch(() => {
          setSearch({ ...search, to_department_id: '', to_department_cd: '', to_department_name: '' });
        });
    }
  }

  async function handleToDepartment2(event: any) {
    const query = `department_code=${event.target.value}&data_date=${moment(startDate)
      .endOf('month')
      .format('YYYY-MM-DD')}`;
    toChoose3Ref.current.click();
    if (event.target.value !== '') {
      await assetAddService
        .fetchDepartment(query)
        .then((department) => {
          setToDepartment2({ name: department.data.name, code: department.data.cd });
          setSearch({
            ...search,
            to_department_id: department.data.id.toString(),
            to_department_cd: department.data.cd,
            to_department_name: department.data.name,
          });
        })
        .catch(() => {
          setSearch({ ...search, to_department_id: '', to_department_cd: '', to_department_name: '' });
        });
    }
  }

  async function handleToEmployee(event: any) {
    const query = `employee_code=${event.target.value}&data_date=${moment(startDate)
      .endOf('month')
      .format('YYYY-MM-DD')}`;
    toChoose3Ref.current.click();
    if (event.target.value !== '') {
      await assetAddService
        .fetchEmployee(query)
        .then((employee) => {
          setSearch({ ...search, to_employee_cd: employee.data.cd, to_employee_name: employee.data.name });
        })
        .catch(() => {
          setSearch({ ...search, to_employee_cd: '', to_employee_name: '' });
        });
    }
  }

  async function handleToStoragePlace(event: any) {
    toChoose1Ref.current.click();
    const storage_place_id = event.target.value;
    setSearch({ ...search, to_storage_place_id: storage_place_id });
  }

  function handleToChangeCompany1(event: any) {
    const companySplit = event.target.value.split('_');
    toChoose2Ref.current.click();
    const company = {
      id: companySplit[0],
      cd: companySplit[1],
      short_name: companySplit[2],
    };
    setDefaultvalueToCompany1(company.id+ '_' + company.cd + '_' + company.short_name);
    setToDepartment1({ name: '', code: '' });
    setSearch({ ...search, to_company_cd: company.cd, to_company_id: company.id, from_department_id: '', to_department_cd: '' });
  }
  function handleToChangeCompany2(event: any) {
    const companySplit = event.target.value.split('_');
    toChoose3Ref.current.click();
    const company = {
      id: companySplit[0],
      cd: companySplit[1],
      short_name: companySplit[2],
    };
    setDefaultvalueToCompany2(company.id+ '_' + company.cd + '_' + company.short_name);
    setToDepartment2({ name: '', code: '' });
    setSearch({ ...search, to_company_cd: company.cd, to_company_id: company.id, from_department_id: '', to_department_cd: '', to_employee_cd: '', to_employee_name: '' });
  }

  function handleManagementNo(event: any) {
    setSearch({ ...search, management_no: event.target.value });
  }
  function handleAccountNo(event: any) {
    setSearch({ ...search, accounting_no: event.target.value });
  }

  function handleChangeStartDate(date: any) {
    setStartDate(date);
    setSearch({
      ...search,
      applied_start_date: moment(date).format('YYYY-MM-DD'),
    });
  }

  function handleChangeEndDate(date: any) {
    setEndDate(date);
    setSearch({
      ...search,
      applied_end_date: moment(date).format('YYYY-MM-DD'),
    });
  }

  const dataCSV: MovementHistoryCSV[] = [];
  function handleExportCSV(rows: TransferHistory[]) {
    for (const row of rows) {
      dataCSV.push({
        applied_start_date: row.applied_start_date,
        management_no: row.asset.management_no,
        accounting_no: row.asset.accounting_no,
        classification: row.asset.classification?.name,
        manufacturer: row.asset.manufacturer?.name,
        model: row.asset.model?.name,
        current_use: row.asset.current_use?.name,
        purchased_date: moment(row.asset.purchased_date).format('YYYY-MM-DD').toString(),
        purchased_price: row.asset.purchased_price.toString(),
        from_company: row.from_company?.name,
        from_department: row.from_department?.name,
        from_storage_place: row.from_storage_place?.name,
        from_employee: row.from_employee?.name,
        to_company: row.to_company?.name,
        to_department: row.to_department?.name,
        to_employee: row.to_employee?.name,
        to_storage_place: row.to_storage_place?.name,
        updated_date: moment(row.updated_date).format('YYYY-MM-DD'),
        updated_employee: row.updated_employee?.name,
      });
    }
  }
  handleExportCSV(assetTransferHistory);

  async function handleSubmit() {
    const typeF = Number(typeFrom);
    const typeT = Number(typeTo);
    let dataQuery = search;
    switch (typeF) {
      case constType.CHOOSE_1: {
        dataQuery = {
          ...dataQuery,
          from_company_id: '',
          from_company_cd: '',
          from_department_cd: '',
          from_department_id: '',
          from_department_name: '',
          from_employee_cd: '',
          from_employee_name: '',
        };
        break;
      }
      case constType.CHOOSE_2: {
        dataQuery = {
          ...dataQuery,
          from_employee_cd: '',
          from_employee_name: '',
          from_storage_place_id: '',
        };
        break;
      }
      case constType.CHOOSE_3: {
        dataQuery = {
          ...dataQuery,
          from_storage_place_id: '',
        };
        break;
      }
      default: {
        dataQuery = {
          ...dataQuery,
          from_company_id: '',
          from_company_cd: '',
          from_department_cd: '',
          from_department_name: '',
          from_employee_cd: '',
          from_employee_name: '',
          from_storage_place_id: '',
        };
      }
    }
    switch (typeT) {
      case constType.CHOOSE_1: {
        dataQuery = {
          ...dataQuery,
          to_company_id: '',
          to_company_cd: '',
          to_department_cd: '',
          to_department_id: '',
          to_department_name: '',
          to_employee_cd: '',
          to_employee_name: '',
        };
        break;
      }
      case constType.CHOOSE_2: {
        dataQuery = {
          ...dataQuery,
          to_employee_cd: '',
          to_employee_name: '',
          to_storage_place_id: '',
        };
        break;
      }
      case constType.CHOOSE_3: {
        dataQuery = {
          ...dataQuery,
          to_storage_place_id: '',
        };
        break;
      }
      default: {
        dataQuery = {
          ...dataQuery,
          to_company_id: '',
          to_company_cd: '',
          to_department_cd: '',
          to_department_id: '',
          to_department_name: '',
          to_employee_cd: '',
          to_employee_name: '',
          to_storage_place_id: '',
        };
      }
    }

    const query = Object.entries(dataQuery)
      .map(([key, val]) => `${key}=${val}`)
      .join('&');
    // call API
    await movementHisotryService
      .filterAsset(query)
      .then((item) => {
        setAssetTransferHistory(item.data);
      })
      .catch((err) => console.log);
  }
  const permissions = useAppSelector(selectPermissionByFeature('asset'));
  const linkToAssetEdit = (value: number, management_no: string) => {
    if (permissions && permissions.includes('show_link_asset_edit')) {
      return (
        <a target="_blank" rel="noreferrer" href={`/asset/edit/${value}`}>
          {management_no}
        </a>
      );
    } else {
      return <>{management_no}</>;
    }
  };
  const handlePageBack = () => {
    history.push('/');
  };

  return (
    <div className="page-wrapper">
      <div className="page-header">
        <StatusHeader actionName="back" onAction={handlePageBack} />
      </div>
      <div className="page-main">
        <div className="page-content">
          <Card className="mb-5">
            <Card.Header className="text-left">
              {t('MovementHistoryFeature.Text.search_condition')}
              &nbsp; &nbsp; &nbsp;
              <span className="text-danger">{t('MovementHistoryFeature.Text.it_is_a_required_field')} </span>
            </Card.Header>
            <Card.Body>
              <Accordion className="mb-3" defaultActiveKey={['0', '1', '2']} alwaysOpen>
                <Accordion.Item eventKey="0" className="movement-information">
                  <Accordion.Header>{t('MovementHistoryFeature.Text.movement_information')} </Accordion.Header>
                  <Accordion.Body>
                    <div className="area-search-date">
                      <div className="d-flex mb-3 align-items-center">
                        <div>
                          <span>
                            <span className="text-danger">※</span>
                            <span>{t('MovementHistoryFeature.Text.moving_day')}</span>&nbsp;&nbsp;
                          </span>
                        </div>
                        <div>
                          <div className="d-flex" style={{ maxWidth: '350px' }}>
                            <CustomDatePicker
                            value={startDate}
                            onChange={(date: Date | null) => {handleChangeStartDate(date)}}
                            customInputComponent={<CustomInputDatePicker/>}
                            startDate={startDate}
                            endDate={endDate}
                            minDate={null}
                            />
                            <span className="align-self-center px-2">～</span>
                            <CustomDatePicker
                            value={endDate}
                            onChange={(date: Date | null) => {handleChangeEndDate(date)}}
                            customInputComponent={<CustomInputDatePicker/>}
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="moving-source-1">
                      <div className="mb-3 p-3 bg-main">
                        <Row>
                          <Col xs={2} className="d-flex justify-content-center align-items-center">
                            <span>{t('MovementHistoryFeature.Text.moving_source')}</span>
                          </Col>
                          <Col xs={9} className="d-flex flex-column">
                            <Row>
                              <Form.Check
                                type="radio"
                                label={t('MovementHistoryFeature.Text.sub_inventory_transfer')}
                                defaultChecked
                                name="type_from"
                                id="radio_button_1"
                                onChange={handleChangeTypeFrom}
                                value={constType.CHOOSE_1}
                                ref={fromChoose1Ref}
                              />
                            </Row>
                            <Row className="mb-2">
                              <Col xs sm="1"></Col>
                              <Form.Label className="col-2 col-form-label">
                                {t('MovementHistoryFeature.Text.storage_location_selection')}
                              </Form.Label>
                              <Col xs={2}>
                                <Form.Select
                                  name="to_storage_place_id"
                                  onChange={handleFromStoragePlace}
                                  disabled={fromDisableOption1}
                                >
                                  <option value="">---{t('OwnershipChangeModal.to_storage_place_id')}---</option>
                                  {preDataLoad.storage_places.map((storage_place) => {
                                    return (
                                      <option key={storage_place.id} value={storage_place.id}>
                                        {storage_place.name}
                                      </option>
                                    );
                                  })}
                                </Form.Select>
                              </Col>
                            </Row>
                            <Row>
                              <Form.Check
                                type="radio"
                                label={t('MovementHistoryFeature.Text.affiliation_transfer')}
                                name="type_from"
                                id="radio_button_2"
                                onChange={handleChangeTypeFrom}
                                value={constType.CHOOSE_2}
                                ref={fromChoose2Ref}
                              />
                            </Row>
                            <Row className="text-center mb-3">
                              <Col xs sm="1"></Col>
                              <Form.Label className="col-2 col-form-label">
                                {t('MovementHistoryFeature.Text.company')}
                              </Form.Label>
                              <Col xs={2}>
                                <Form.Select
                                  name="from_company_cd"
                                  onChange={handleFromChangeCompany1}
                                  disabled={fromDisableOption2}
                                  ref={fromCompany1Ref}
                                  value={defaultValueFromCompany1}
                                >
                                  <option value="">---{t('OwnershipChangeModal.to_company_id')}---</option>
                                  {companies.map((company) => {
                                    return (
                                      <option
                                        key={company.id}
                                        value={`${company.id}_${company.cd}_${company.name}`}
                                      >
                                        {company.name}
                                      </option>
                                    );
                                  })}
                                </Form.Select>
                              </Col>
                            </Row>
                            <Row className="text-center mb-3">
                              <Col xs sm="1"></Col>
                              <Form.Label className="col-2 col-form-label">
                                {t('MovementHistoryFeature.Text.from_department_code')}
                              </Form.Label>
                              <Col xs={2}>
                                <Form.Control
                                  name="from_department_code"
                                  onChange={handleFromDepartment1}
                                  disabled={fromDisableOption2}
                                  value={fromDepartment1.code}
                                ></Form.Control>
                              </Col>
                              <Form.Label className="col-1 col-form-label">
                                {t('MovementHistoryFeature.Text.from_department_name')}
                              </Form.Label>
                              <Col xs={2}>
                                <Form.Control
                                  name="from_department_name"
                                  value={fromDepartment1.name}
                                  disabled
                                ></Form.Control>
                              </Col>
                              <Col>
                                <Button onClick={handleFromShowDepartmentSearchModal1} disabled={fromDisableOption2}>
                                  {t('MovementHistoryFeature.Component.department_search')}
                                </Button>
                              </Col>
                            </Row>

                            <Row>
                              <Form.Check
                                type="radio"
                                label={t('MovementHistoryFeature.Text.affiliation_movement')}
                                name="type_from"
                                id="radio_button_3"
                                onChange={handleChangeTypeFrom}
                                value={constType.CHOOSE_3}
                                ref={fromChoose3Ref}
                              />
                            </Row>
                            <Row className="text-center mb-3">
                              <Col xs sm="1"></Col>
                              <Form.Label className="col-2 col-form-label">
                                {t('MovementHistoryFeature.Text.company')}
                              </Form.Label>
                              <Col xs={2}>
                                <Form.Select
                                  name="from_company_cd"
                                  onChange={handleFromChangeCompany2}
                                  disabled={fromDisableOption3}
                                  ref={fromCompany2Ref}
                                  value={defaultValueFromCompany2}
                                >
                                  <option value="">---{t('OwnershipChangeModal.to_company_id')}---</option>
                                  {companies.map((company) => {
                                    return (
                                      <option
                                        key={company.id}
                                        value={`${company.id}_${company.cd}_${company.name}`}
                                      >
                                        {company.name}
                                      </option>
                                    );
                                  })}
                                </Form.Select>
                              </Col>
                            </Row>
                            <Row className="text-center mb-3">
                              <Col xs sm="1"></Col>
                              <Form.Label className="col-2 col-form-label">
                                {t('MovementHistoryFeature.Text.from_department_code')}
                              </Form.Label>
                              <Col xs={2}>
                                <Form.Control
                                  name="from_department_code"
                                  onChange={handleFromDepartment2}
                                  disabled={fromDisableOption3}
                                  value={fromDepartment2.code}
                                ></Form.Control>
                              </Col>
                              <Form.Label className="col-1 col-form-label">
                                {t('MovementHistoryFeature.Text.from_department_name')}
                              </Form.Label>
                              <Col xs={2}>
                                <Form.Control
                                  name="from_department_name"
                                  value={fromDepartment2.name}
                                  disabled
                                ></Form.Control>
                              </Col>
                              <Col>
                                <Button onClick={handleFromShowDepartmentSearchModal2} disabled={fromDisableOption3}>
                                  {t('MovementHistoryFeature.Component.department_search')}
                                </Button>
                              </Col>
                            </Row>
                            <Row className="text-center">
                              <Col xs sm="1"></Col>
                              <Form.Label className="col-2 col-form-label">
                                {t('MovementHistoryFeature.Text.employee_code')}
                              </Form.Label>
                              <Col xs={2}>
                                <Form.Control
                                  name="from_employee_code"
                                  onChange={handleFromEmployee}
                                  value={search.from_employee_cd}
                                  disabled={fromDisableOption3}
                                ></Form.Control>
                              </Col>
                              <Form.Label className="col-1 col-form-label">
                                {t('MovementHistoryFeature.Text.employee_name')}
                              </Form.Label>
                              <Col xs={2}>
                                <Form.Control
                                  name="from_employee_name"
                                  value={search.from_employee_name}
                                  disabled
                                ></Form.Control>
                              </Col>
                              <Col>
                                <Button onClick={handleFromShowEmployeeSearchModal} disabled={fromDisableOption3}>
                                  {t('MovementHistoryFeature.Component.employee_search')}
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </div>

                    <div className="moving-source-2">
                      <div className="mb-3 p-3 bg-yellow">
                        <Row>
                          <Col xs={2} className="d-flex justify-content-center align-items-center">
                            <span>{t('MovementHistoryFeature.Table.destination')}</span>
                          </Col>
                          <Col xs={9} className="d-flex flex-column">
                            <Row>
                              <Form.Check
                                type="radio"
                                label={t('MovementHistoryFeature.Text.sub_inventory_transfer')}
                                defaultChecked
                                name="type_to"
                                id="to_radio_button_1"
                                onChange={handleChangeTypeTo}
                                value={constType.CHOOSE_1}
                                ref={toChoose1Ref}
                              />
                            </Row>
                            <Row className="mb-2">
                              <Col xs sm="1"></Col>
                              <Form.Label className="col-2 col-form-label">
                                {t('MovementHistoryFeature.Text.storage_location_selection')}
                              </Form.Label>
                              <Col xs={2}>
                                <Form.Select
                                  name="to_storage_place_id"
                                  onChange={handleToStoragePlace}
                                  disabled={toDisableOption1}
                                >
                                  <option value="">---{t('OwnershipChangeModal.to_storage_place_id')}---</option>
                                  {preDataLoad.storage_places.map((storage_place) => {
                                    return (
                                      <option key={storage_place.id} value={storage_place.id}>
                                        {storage_place.name}
                                      </option>
                                    );
                                  })}
                                </Form.Select>
                              </Col>
                            </Row>
                            <Row>
                              <Form.Check
                                type="radio"
                                label={t('MovementHistoryFeature.Text.affiliation_transfer')}
                                name="type_to"
                                id="to_radio_button_2"
                                onChange={handleChangeTypeTo}
                                value={constType.CHOOSE_2}
                                ref={toChoose2Ref}
                              />
                            </Row>
                            <Row className="text-center mb-3">
                              <Col xs sm="1"></Col>
                              <Form.Label className="col-2 col-form-label">
                                {t('MovementHistoryFeature.Text.company')}
                              </Form.Label>
                              <Col xs={2}>
                                <Form.Select
                                  name="to_company_cd"
                                  onChange={handleToChangeCompany1}
                                  disabled={toDisableOption2}
                                  ref={toCompany1Ref}
                                  value={defaultValueToCompany1}
                                >
                                  <option value="">---{t('OwnershipChangeModal.to_company_id')}---</option>
                                  {companies.map((company) => {
                                    return (
                                      <option
                                        key={company.id}
                                        value={`${company.id}_${company.cd}_${company.name}`}
                                      >
                                        {company.name}
                                      </option>
                                    );
                                  })}
                                </Form.Select>
                              </Col>
                            </Row>
                            <Row className="text-center mb-3">
                              <Col xs sm="1"></Col>
                              <Form.Label className="col-2 col-form-label">
                                {t('MovementHistoryFeature.Text.to_department_code')}
                              </Form.Label>
                              <Col xs={2}>
                                <Form.Control
                                  name="to_department_code"
                                  onChange={handleToDepartment1}
                                  disabled={toDisableOption2}
                                  value={toDepartment1.code}
                                ></Form.Control>
                              </Col>
                              <Form.Label className="col-1 col-form-label">
                                {t('MovementHistoryFeature.Text.to_department_name')}
                              </Form.Label>
                              <Col xs={2}>
                                <Form.Control
                                  name="to_department_name"
                                  value={toDepartment1.name}
                                  disabled
                                ></Form.Control>
                              </Col>
                              <Col>
                                <Button onClick={handleToShowDepartmentSearchModal1} disabled={toDisableOption2}>
                                  {t('MovementHistoryFeature.Component.department_search')}
                                </Button>
                              </Col>
                            </Row>

                            <Row>
                              <Form.Check
                                type="radio"
                                label={t('MovementHistoryFeature.Text.affiliation_movement')}
                                name="type_to"
                                id="to_radio_button_3"
                                onChange={handleChangeTypeTo}
                                value={constType.CHOOSE_3}
                                ref={toChoose3Ref}
                              />
                            </Row>
                            <Row className="text-center mb-3">
                              <Col xs sm="1"></Col>
                              <Form.Label className="col-2 col-form-label">
                                {t('MovementHistoryFeature.Text.company')}
                              </Form.Label>
                              <Col xs={2}>
                                <Form.Select
                                  name="to_company_cd"
                                  onChange={handleToChangeCompany2}
                                  disabled={toDisableOption3}
                                  ref={toCompany2Ref}
                                  value={defaultValueToCompany2}
                                >
                                  <option value="">---{t('OwnershipChangeModal.to_company_id')}---</option>
                                  {companies.map((company) => {
                                    return (
                                      <option
                                        key={company.id}
                                        value={`${company.id}_${company.cd}_${company.name}`}
                                      >
                                        {company.name}
                                      </option>
                                    );
                                  })}
                                </Form.Select>
                              </Col>
                            </Row>
                            <Row className="text-center mb-3">
                              <Col xs sm="1"></Col>
                              <Form.Label className="col-2 col-form-label">
                                {t('MovementHistoryFeature.Text.to_department_code')}
                              </Form.Label>
                              <Col xs={2}>
                                <Form.Control
                                  name="to_department_code"
                                  onChange={handleToDepartment2}
                                  disabled={toDisableOption3}
                                  value={toDepartment2.code}
                                ></Form.Control>
                              </Col>
                              <Form.Label className="col-1 col-form-label">
                                {t('MovementHistoryFeature.Text.to_department_name')}
                              </Form.Label>
                              <Col xs={2}>
                                <Form.Control
                                  name="to_department_name"
                                  value={toDepartment2.name}
                                  disabled
                                ></Form.Control>
                              </Col>
                              <Col>
                                <Button onClick={handleToShowDepartmentSearchModal2} disabled={toDisableOption3}>
                                  {t('MovementHistoryFeature.Component.department_search')}
                                </Button>
                              </Col>
                            </Row>
                            <Row className="text-center">
                              <Col xs sm="1"></Col>
                              <Form.Label className="col-2 col-form-label">
                                {t('MovementHistoryFeature.Text.employee_code')}
                              </Form.Label>
                              <Col xs={2}>
                                <Form.Control
                                  name="to_employee_code"
                                  onChange={handleToEmployee}
                                  disabled={toDisableOption3}
                                  value={search.to_employee_cd}
                                ></Form.Control>
                              </Col>
                              <Form.Label className="col-1 col-form-label">
                                {t('MovementHistoryFeature.Text.employee_name')}
                              </Form.Label>
                              <Col xs={2}>
                                <Form.Control
                                  name="to_employee_name"
                                  value={search.to_employee_name}
                                  disabled
                                ></Form.Control>
                              </Col>
                              <Col>
                                <Button onClick={handleToShowEmployeeSearchModal} disabled={toDisableOption3}>
                                  {t('MovementHistoryFeature.Component.employee_search')}
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1" className="basic-information">
                  <Accordion.Header> {t('MovementHistoryFeature.Text.basic_information')}</Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Form.Label className="col-sm-2 col-form-label text-end">
                        {t('MovementHistoryFeature.Text.asset_management_number')}
                      </Form.Label>
                      <Col xs sm="2">
                        <Form.Control name="asset_management_number" onChange={handleManagementNo}></Form.Control>
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2" className="accounting-information">
                  <Accordion.Header>{t('MovementHistoryFeature.Text.accounting_information')}</Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Form.Label className="col-sm-2 col-form-label text-end">
                        {t('MovementHistoryFeature.Text.accounting_number')}
                      </Form.Label>
                      <Col xs sm="2">
                        <Form.Control name="accounting_number" onChange={handleAccountNo}></Form.Control>
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <div className="text-center">
                <Button onClick={handleSubmit}>{t('MovementHistoryFeature.Component.search')}</Button>
              </div>
            </Card.Body>
          </Card>
          <Row className="mb-3">
            <Col>
              <div className="mb-3">{t('MovementHistoryFeature.Text.search_result')}</div>
              <div>
                <span className="d-inline-block me-4">{t('MovementHistoryFeature.Text.number_of_searches')}</span>
                <span>
                  {assetTransferHistory?.length} &nbsp;{t('MovementHistoryFeature.Text.subject')}
                </span>
              </div>
            </Col>
            <Col className="d-flex justify-content-end">
              <div className="align-self-end">
                <ExportCSV rows={dataCSV}></ExportCSV>
              </div>
            </Col>
          </Row>
          <div className="sticky-table table-movement-history" style={{ maxHeight: '500px' }}>
            <Table hover>
              <thead>
                <tr>
                  <th>{t('MovementHistoryFeature.Table.moving_day')}</th>
                  <th>{t('MovementHistoryFeature.Table.control_number')}</th>
                  <th>{t('MovementHistoryFeature.Table.accounting_number')}</th>
                  <th>{t('MovementHistoryFeature.Table.classification')}</th>
                  <th>{t('MovementHistoryFeature.Table.manufacturer')}</th>
                  <th>{t('MovementHistoryFeature.Table.model_name')}</th>
                  <th>{t('MovementHistoryFeature.Table.usage')}</th>
                  <th>{t('MovementHistoryFeature.Table.purchase_date')}</th>
                  <th>{t('MovementHistoryFeature.Table.purchase_price')}</th>
                  <th className="bg-yellow">
                    <span>{t('MovementHistoryFeature.Table.source_company')}</span>
                  </th>
                  <th className="bg-yellow">
                    <span>{t('MovementHistoryFeature.Table.source_department')}</span>
                  </th>
                  <th className="bg-yellow">
                    <span>{t('MovementHistoryFeature.Table.moving_source')}</span>
                    <span>{t('MovementHistoryFeature.Table.source_location')}</span>
                  </th>
                  <th className="bg-yellow">
                    <span>{t('MovementHistoryFeature.Table.source_user')}</span>
                  </th>
                  <th className="bg-green">
                    <span>{t('MovementHistoryFeature.Table.destination_company')}</span>
                  </th>
                  <th className="bg-green">
                    <span>{t('MovementHistoryFeature.Table.destination_department')}</span>
                  </th>
                  <th className="bg-green">
                    <span>{t('MovementHistoryFeature.Table.destination')}</span>
                    <span>{t('MovementHistoryFeature.Table.source_location')}</span>
                  </th>
                  <th className="bg-green">
                    <span>{t('MovementHistoryFeature.Table.destination_user')}</span>
                  </th>
                  <th>
                    <span>{t('MovementHistoryFeature.Table.update_date')}</span>
                  </th>
                  <th>
                    <span>{t('MovementHistoryFeature.Table.changer')}</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {assetTransferHistory &&
                  assetTransferHistory.map((row, idx) => (
                    <tr key={idx}>
                      <td>{row.applied_start_date}</td>
                      <td>{linkToAssetEdit(row.id, row.asset.management_no)}</td>
                      <td>{row.asset.accounting_no}</td>
                      <td>{row.asset.classification?.name}</td>
                      <td>{row.asset.manufacturer?.name}</td>
                      <td>{row.asset.model?.name}</td>
                      <td>{row.asset.current_use?.name}</td>
                      <td>{moment(row.asset.purchased_date).format('YYYY-MM-DD')}</td>
                      <td>{new Intl.NumberFormat().format(row.asset.purchased_price)}</td>

                      <td>{row.from_company?.name}</td>
                      <td>{row.from_department?.name}</td>
                      <td>{row.from_storage_place?.name}</td>
                      <td>{row.from_employee?.username}</td>

                      <td>{row.to_company?.name}</td>
                      <td>{row.to_department?.name}</td>
                      <td>{row.to_storage_place?.name}</td>
                      <td>{row.to_employee?.username}</td>
                      <td>{moment(row.updated_date).format('YYYY-MM-DD')}</td>
                      <td>{row.updated_employee?.username}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MovementHistory;
